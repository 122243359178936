
/* Dark Blue */
/* #4183c4 */

/* Bright blue */
/* #61dafb */
:root {
  --amplify-primary-color: #4183c4;
  --amplify-primary-tint: #4183c4;
  --amplify-primary-shade: #4183c4;
  --amplify-grey:#828282; 
}

#root {
  display:inline-block;
  width:100%;
  min-height: 100vh; 
  display: flex; 
  flex-direction: column;
}


.App {
  text-align: center;
  background: linear-gradient(to bottom, #fff, #dfe7ed);
  min-height: calc(100vh);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.sign-in-button .oauth {
  display: none !important;

}

/* /amplify-form-section.hydrated amplify-federated-buttons.hydrated */
:host {
  display: none !important;
}

.authenticator amplify-authenticator amplify-sign-in :root amplify-form-section amplify-federated-buttons {
  display: none !important;
}


h2 {
  font-weight: 400 !important;
}

h3 {
 margin:0.3em !important;
 font-weight: 400 !important;
}


table {
  padding-top:0px !important;
  border-radius:5px;
  margin-bottom:10px;
}


.table-header {  
  cursor: pointer;
  padding-bottom: 0px !important;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  background: rgb(233,233,233);
  background: linear-gradient(0deg, rgba(233,233,233,1) 0%, rgba(255,255,255,1) 70%, rgba(240,240,240,1) 100%) !important;
  color: #306f9c;
  font-weight: 500;
}

.table-pagination {
  /* width: 80%; */
  width:100%;
  height: 100%;
  display: inline-block;
  margin:10em;
  margin-top:0;
  margin:0em;
  margin-bottom:0;
  
}

.table-header-body {
  display: inline-block;
  overflow:auto !important;
  width:100%;
  /* width:calc(100vw - 20em); */
  /* width: 80%; */
  height:calc(100vh - 8em);
  margin:0em;
  margin-top:0;
  padding-top:0em;
  -webkit-box-shadow: 0px 2px 5px 4px rgba(0,0,0,0.12);
  -moz-box-shadow: 0px 2px 5px 4px rgba(0,0,0,0.12);
  box-shadow: 0px 2px 5px 4px rgba(0,0,0,0.12);
}

.header-product-p {
  margin-bottom:0px;
  padding-left:6px;
}

.MuiTypography-root {
  width:100%;
  text-align: left;
}


.Toastify__toast-container {
  z-index: 1499 !important;
}

.Toastify__toast-body {
  flex:1 !important;
}

.Toastify__toast {
  border-radius: 5px !important; 
}

.toastify-button:hover {
  text-decoration: underline;
}

.toastify-button {
  text-decoration: none;

  color:white;
  /* background-color: white; */
  border-radius: 4px;
  margin-left: auto;
  margin-top:0px;
  /* margin-right: auto; */
  /* width: 110px; */
  padding:5px;
  padding-left:0px;
  padding-right:0px;
  font-weight: bold;
  font-size: .8em;
  text-align: right;
}

.toastify-icon {
  position: absolute;
  left:3%;
  top:10%;
  font-size: 2.4em;
}


.App-header {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.MuiList-root {
  padding-bottom: 0px !important;
}

.footer {
  background-color:#424545 !important;
}

.moveInFinished {
  transform:translateY(0px) !important;
}

.moveIn1 {
  /* transform: translate3d(-100vw, 0, 0); */
  /* transition: transform 1s cubic-bezier(0, .52, 0, 1); */
  /* -webkit-animation: slideOut 0.33s forwards;
  -moz-animation: slideOut 0.33s forwards;
  animation: slideOut 0.33s forwards; */
  /* transform: translateY(-1000) !important; */
  -webkit-animation: slideIn 0.33s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: slideIn 0.33s; /* Firefox < 16 */
   -ms-animation: slideIn 0.33s; /* Internet Explorer */
    -o-animation: slideIn 0.33s; /* Opera < 12.1 */
       animation: slideIn 0.33s;
       animation-fill-mode: forwards;
       -webkit-animation-fill-mode: forwards;
       animation-delay: 0.3s;

}



.moveOut1 {
  /* transform: translate3d(-100vw, 0, 0); */
  /* transition: transform 1s cubic-bezier(0, .52, 0, 1); */
  /* -webkit-animation: slideOut 0.33s forwards;
  -moz-animation: slideOut 0.33s forwards;
  animation: slideOut 0.33s forwards; */
  -webkit-animation: slideOut 0.4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: slideOut 0.4s; /* Firefox < 16 */
   -ms-animation: slideOut 0.4s; /* Internet Explorer */
    -o-animation: slideOut 0.4s; /* Opera < 12.1 */
       animation: slideOut 0.4s;
       animation-fill-mode: forwards;
       -webkit-animation-fill-mode: forwards;
       animation-delay: 0.3s;

}


.fadeIn {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
   -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
       animation: fadein 1s;
       animation-fill-mode: forwards;
       -webkit-animation-fill-mode: forwards;
}


.fadeOut {
  -webkit-animation: fadeout 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 1s; /* Firefox < 16 */
   -ms-animation: fadeout 1s; /* Internet Explorer */
    -o-animation: fadeout 1s; /* Opera < 12.1 */
       animation: fadeout 1s;
       animation-fill-mode: forwards;
       -webkit-animation-fill-mode: forwards;
       animation-delay: 0.5s;
}

.moveIn  {
  -webkit-animation: movein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: movein 1s; /* Firefox < 16 */
   -ms-animation: movein 1s; /* Internet Explorer */
    -o-animation: movein 1s; /* Opera < 12.1 */
       animation: movein 1s;
       animation-fill-mode: forwards;
       -webkit-animation-fill-mode: forwards;
       animation-delay: 0.5s;
}


.moveOut  {
  -webkit-animation: moveout 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: moveout 1s; /* Firefox < 16 */
   -ms-animation: moveout 1s; /* Internet Explorer */
    -o-animation: moveout 1s; /* Opera < 12.1 */
       animation: moveout 1s;
       animation-fill-mode: forwards;
       -webkit-animation-fill-mode: forwards;
       animation-delay: 0.5s;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@keyframes movein {
  from { transform: translateY(-1000); }
  to   { transform: translateY(0); }
}

@keyframes moveout {
  from { transform: translateY(0); }
  to   { transform: translateY(1000); }
}



@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-1200px);
  }
}


@-webkit-keyframes slideIn {
  0% {
    transform: translateY(-1200px);
  }
  100% {
    transform: translateY(0);
  }
}
@-moz-keyframes slideIn {
  0% {
    transform: translateY(-900px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideIn {
  0% {
    transform: translateY(-900px);
  }
  100% {
    transform: translateY(0);
  }
}




.sidebar {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 250px;
  -webkit-transition: right 0.2s ease-out;
  -moz-transition: right 0.2s ease-out;
  transition: right 0.2s ease-out;
  cursor: pointer;
  background-color: cornflowerblue;
}


.menuList {
  background-image:linear-gradient(to bottom right, #2D3238 , #424545) !important;
  height:100%;
  padding-top:65px !important;
}

.onboarding-container {
  width:90%;
  margin-top:7em;
  max-width: 500px;
  margin-left:auto;
  margin-right:auto;
  border: 1px solid #ccc;
  border-radius: 0px;
  padding:50px;
  padding-top:40px;
  padding-bottom: 60px;
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  background:white;
  overflow: hidden;
  /* transform: translateY(-1000) !important; */
  text-align: center;
  transform:translateY(-1000px);
  
}

.onboarding-large-icon {
  width:140px !important;
  height:140px  !important;
  fill:#4183c4 !important;
  transform: scaleX(-1);
}


.point {
  cursor: pointer;
}

.justify {
  text-align: justify;
}

.submit-button {
  margin-left:30px !important;
}

.continue-sign-in {

  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor:pointer;
  padding:10px;
  margin-top:20px;
  padding-left:30px;
  padding-right:30px;
  background-image:linear-gradient(#abe9ff, #2f73d8);
  border:1px solid #2722b3;
  color:rgb(242, 244, 247) !important;
  border-radius: 2px;
  font-weight: 400;

}

.recaptcha-form > div > div {
  display: flex;
  place-content: center;
}

.mwsAuthTokenButton {
  cursor:pointer;
  padding:10px;
  margin-top:20px;
  padding-left:30px;
  padding-right:30px;
  background-image:linear-gradient(#ffe8ab, #f5c645);
  border:1px solid #b38b22;
  color:black !important;
  border-radius: 2px;
  font-weight: 400;

}

.mwsAuthTokenButton:hover {
  background-image:linear-gradient(#ebd498, #e1b144)
}

.link {
  color:#4183c4;
  cursor: pointer;
}

.mwsAuthTokenButton:active:focus {
  outline:5px auto #4183c4;
  outline-offset:-2px;
}

.mwsAuthTokenButton:active, .mwsAuthTokenButton:focus{
  outline:5px auto #4183c4;
  outline-offset:-2px;
}

.checkmark {
  color:#38c238;
  font-size:100px !important;
}

.mwsAuthTokenButton a {
  color:black !important;
}

.mwsAuthTokenButton a:visited {
  color:black !important;
}

.MuiInputBase-input .search-input {
  height:0.4em !important;
  width:300px;
}

.statusBar {
  width:100%;
  background-color:#4183c4; 
  color:white;
  top:64px;
  height:56px;
  padding-left:170px;
  position: fixed;

}

.statusBarText {
  font-size: 1.4em;
  display: inline-block;  
  padding-top:12px;
  padding-left:17px;
  padding-bottom:6px;
  vertical-align: middle;
}

.statusBarIcon {
  display: inline-block;
}

.statusBarIconClose {
  display: inline-block;
  padding-right:30px;
  padding-top:12px;
}

.statusBarIconClose:hover {

  cursor: pointer;
  color:#ccc;
}

.standard-card {
  max-width: 400px;
  min-height:300px;
  margin:8px;
  margin-left:0px;
  border:1px solid #ccc;
}

.full-width-card {
  /* max-width: 400px; */
  min-height:150px;
  margin:8px;
  margin-left:0px;
  border:1px solid #ccc;
}


.settlement-report-card {
  max-height: 100px;
  /* min-height:300px; */
  margin:8px;
  margin-left:0px;
  border:1px solid #ccc;
}

.settlement-report-card-left {
  width: 80%;
  display: inline-block;
  vertical-align: top;
}
.settlement-report-card-left p{
  margin:0;
}

.settlement-report-card-right div{
  float:right
}

.settlement-report-card-right {
  width: 20%;
  /* display: inline-block; */
  vertical-align: top;
  /* margin-left: auto;
  margin-right: auto; */
  float:right;
}

.settlement-card {
  max-width: 400px;
  /* min-height:300px; */
  margin:8px;
  margin-left:0px;
  border:1px solid #ccc;
  padding:0.5em;
}

.stlmnt-period {
  font-size: 1.4em;
  font-weight: bold;
}

.stlmnt-block {
  max-width: 10em;
  display: inline-block;
  margin:0.4em;
  border:1px solid #ccc;
  border-radius: 2px;
  text-align: center;
  vertical-align: top;
}

.invoice-block p{
  margin:0;
}

.invoice-block p.label{
  font-weight: bold;
  padding-top:1em;
}

.invoice-block span.column {
  width:300px;
  display: inline-block;
}

.invoice-block span.column-small {
  width:150px;
  display: inline-block;
}

.invoice-block span.column.right {
  text-align: right;
}


.invoice-block {
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding:1em;
}

.alert-dropdown {
  min-height: 3em;
  padding-top:1em;
  padding-left:1em;
  padding-right:1em;
  font-size: 1.2em;
  font-weight: medium;
  text-align: center; 
  position: fixed; 
  top:3.5em;
  width:100%;
  z-index: 10000;
}

.notification.hide {
  display: none;
  opacity: 0;
  /* top: 6.9em; */
  /* animation: hideOpacity 0.8s forwards; */
  /* animation-play-state: paused;  */
  /* z-index: 100; */
}


.notification.show {
  display: normal;
  position: fixed;
  top:7em;
  /* display: none; */
  /* opacity: 0; */ 
  /* top: -50em;  */
  /* animation: showOpacity 0.8s forwards; */
  /* animation-play-state: paused;  */
  /* z-index: 100; */
}

@keyframes hide {
  100% {top: -50em; }
}

@keyframes show {
  100% {top: 6.9em; }
}

@keyframes hideOpacity {
  100% {opacity: 0; }
}

@keyframes showOpacity {
  100% {opacity: 100; }
}

.stlmnt-block-top {
  padding:2em;
  padding-top:0.4em;
  display: block;
  border-bottom: 1px solid #ccc !important;
  height: 5em;
}

.stlmnt-block-bottom {
  padding:1em;
  display: block;
}


.invoice-card {
  /* max-width: 400px; */
  min-height:100px;
  margin:8px;
  margin-left:0px;
  border:1px solid #ccc;
}
.invoice-card .more-items {
  /* max-width: 400px; */
  /* min-height:100px;
  margin:8px;
  margin-left:0px;
  border:1px solid #ccc; */
  background-color: red;
}



.no-drag {

  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}

.text-center {
  text-align: center;
}

.text-tiny-info {
  display:block;
  font-size:0.6em;
  color:gray;
  padding:8px;
}

.text-card {
  font-size:0.9em;
  padding-left:20px;
  padding:10px;
  border-radius:4px;
}

.text-space {
  height:4em;
  display:block;
}

.item-center {
  margin-left:auto;
  margin-right:auto;
}

.qb-card {
  /* width: 130px;
  height: 195px; */
  /* width: 200px;
  height:100px; */
  max-width:200px;
  margin-left:auto;
  margin-right:auto;
  
  /* background: url("/images/buttons/C2QB_white_btn_lg_default.png") no-repeat; */
  display: inline-block;
  cursor: pointer;
}

.qb-card:active {
  /* background-color: #3e8e41; */
  /* box-shadow: 0 5px #666; */
  transform: translateY(2px);
}



.qb-card:hover {

  background: url("/images/buttons/C2QB_white_btn_lg_hover.png") no-repeat;

}


.MuiDrawer-paperAnchorDockedLeft {
  border-right:none !important;
}

.MuiPickersDay-day {
  padding:0px;

}